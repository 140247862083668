// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loadingOverlay-module__loadingOverlay--_CFFC {\n    position: relative;\n}\n\n.loadingOverlay-module__isLoading--Ahfws {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Utils/loadingOverlay.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".loadingOverlay {\n    position: relative;\n}\n\n.isLoading {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingOverlay": "loadingOverlay-module__loadingOverlay--_CFFC",
	"isLoading": "loadingOverlay-module__isLoading--Ahfws"
};
export default ___CSS_LOADER_EXPORT___;
