/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import Api from "./Api";
import config from "../settings.js";
import { buildRegionUrl } from "../utils.js";

/**
 * This component is used to tracks user visits by associating them with an ID.
 */
class SuiviConsultations {
  constructor(callback, regionCode, region) {
    this.regionCode = regionCode;
    this.region = region;
    this.idUtilisateur = undefined;
    this.obtenirIdUtilisateur(callback);
  }

  /**
   * This function assigns an identifier to the person browsing the site.
   * This incremental identifier is updated with each new access to the user's platform.
   */
  obtenirIdUtilisateur(callback) {
    if (!this.regionCode) {
      this.region = "Inconnue";
    }
    let url = buildRegionUrl(config.api_id_utilisateur, this.region) + "/" + this.regionCode + "/id_utilisateur";
    Api.callApi(url, null, "GET").then(response => {
      this.idUtilisateur = response["id_utilisateur"];
      callback();
    });
  }
}
export default SuiviConsultations;