/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import localConfig, { server_url } from "./settings-local";
const config = {
  DEBUG: false,
  OSM_SOURCE: "agaric",
  osm_raster_tiles_url: "https://terristory-tiles.agaricids.com/mapcache/osm2024/tms/1.0.0/osm/osmwebmercator/",
  rasterMaxExtent: [-1090237.0, 5000000.0, 1280527.0, 7000000.0],
  rasterOrigin: [-1090237.0, 7000000.0],
  rasterResolutions: [156543.03392804097, 78271.51696402048, 39135.75848201024, 19567.87924100512, 9783.93962050256, 4891.96981025128, 2445.98490512564, 1222.99245256282, 611.49622628141, 305.748113140705, 152.8740565703525, 76.43702828517625, 38.21851414258813, 19.109257071294063, 9.554628535647032, 4.777314267823516, 2.388657133911758, 1.194328566955879, 0.5971642834779395],
  osm_vector_tiles_url: server_url + "/styles/klokantech-basic.json",
  zone_vector_tiles_url: server_url + "/layer/#layer#/{z}/{x}/{y}.pbf?",
  api_id_utilisateur: server_url + "/api/#region#",
  api_region_list_url: server_url + "/api/region",
  api_analysis_meta_url: server_url + "/api/#region#/analysis/",
  api_analysis_url: server_url + "/api/#region#/analysis/",
  api_analysis_uitheme_url: server_url + "/api/#region#/analysis/uithemes",
  api_analysis_confid_layers_url: server_url + "/api/#region#/analysis/list/confid/layers/",
  api_analysis_donnees_communes: server_url + "/api/#region#/analysis/donnees_communes/#global_region#/",
  api_export_common_dataset_table: server_url + "/api/#region#/analysis/donnees_communes/#global_region#/export/#table_name#/",
  api_import_common_table: server_url + "/api/#region#/import/common/data/table/#table#",
  api_import_common_indicator: server_url + "/api/#region#/import/common/indicator/#analysis_id#",
  api_import_common_category: server_url + "/api/#region#/import/common/category/#category#",
  api_analysis_uitheme_update_url: server_url + "/api/#region#/analysis/uitheme/order",
  api_analysis_uitheme_rename_url: server_url + "/api/#region#/analysis/uitheme/rename",
  api_analysis_activate_url: server_url + "/api/#region#/analysis/#id#/activate",
  api_analysis_deactivate_url: server_url + "/api/#region#/analysis/#id#/deactivate",
  api_analysis_ajouter_url: server_url + "/api/#region#/analysis/ajouter",
  api_analysis_data_url: server_url + "/api/#region#/analysis/data",
  api_analysis_confid_data_url: server_url + "/api/#region#/analysis/confid/data/#table_name#",
  api_analysis_data_national_url: server_url + "/api/#region#/#territory#/analysis/data",
  api_national_import_metadata: server_url + "/api/national/#region#/import/metadata",
  api_analysis_data_categories_national_url: server_url + "/api/#region#/#territory#/analysis/data/#table_name#/categories",
  api_code_region_national: server_url + "/api/#region#/#territory#/code",
  api_analysis_data_history_url: server_url + "/api/#region#/analysis/data/history",
  api_analysis_data_export: server_url + "/api/#region#/analysis/data/export/#table_name#/",
  api_analysis_data_national_export: server_url + "/api/#region#/#territory#/analysis/data/export/#table_name#/",
  api_activate_territorial_strategy_simulator: server_url + "/api/#region#/#territory#/territorial_strategy/activate",
  api_check_validity_territorial_strategy_simulator: server_url + "/api/#region#/#territory#/territorial_strategy/status",
  api_analysis_export: server_url + "/api/#region#/analysis/export/#id#",
  api_data_units_url: server_url + "/api/#region#/data/units/",
  api_current_perimeter: server_url + "/api/#region#/geographic/perimeter/",
  api_perimeters_available: server_url + "/api/#region#/geographic/perimeters/",
  api_rebuild_perimeters: server_url + "/api/#region#/rebuild/geographic/perimeters/",
  api_convert_data_to_perimeter: server_url + "/api/#region#/convert/data/geographic/perimeter/",
  api_update_perimeters: server_url + "/api/#region#/#region_code#/geographic/perimeter/#year#/",
  api_donnees_territoriales_url: server_url + "/api/#region#/donnees_territoriales/",
  api_transfer_data_from_global_region_url: server_url + "/api/#region#/donnees_territoriales/ajouter/#table_name#/",
  api_liste_donnees_registre: server_url + "/api/#region#/registre",
  api_liste_colonnes_donnees_registre: server_url + "/api/registre/#region#/#table#",
  api_liste_valeurs_colonne_donnees_registre: server_url + "/api/registre/#region#/#colonnes#/categories",
  api_integration_donnees_registre: server_url + "/api/registre/#region#/#table#/integration",
  api_categories_list_url: server_url + "/api/#region#/categories",
  api_categories_data_list_url: server_url + "/api/#region#/categories/data",
  api_categories_modalites_list_url: server_url + "/api/#region#/categories_modalites",
  api_categorie_ajouter_url: server_url + "/api/#region#/categorie/ajouter",
  api_communes_data: server_url + "/api/#region#/communes_ign",
  api_categorie_url: server_url + "/api/#region#/categorie",
  api_categorie_edit_url: server_url + "/api/#region#/categorie/#categorie#/",
  api_donnees_geo: server_url + "/api/#region#/donnees_geographiques",
  api_area_url: server_url + "/api/#region#/zone/",
  api_territorial_levels_url: server_url + "/api/#region#/territorial/levels/",
  api_add_zone_url: server_url + "/api/#region#/add/zone/",
  api_edit_zone_url: server_url + "/api/#region#/edit/zone/",
  api_delete_zone_url: server_url + "/api/#region#/delete/zone/",
  api_toggle_zone_url: server_url + "/api/#region#/toggle/zone/",
  api_order_zone_url: server_url + "/api/#region#/order/zone/",
  api_switch_auto_select_maille_url: server_url + "/api/#region#/config/auto_select_maille/",
  api_area_geometry_url: server_url + "/api/#region#/zone/#zone_type#/#zone_id#/geometry",
  api_liste_annees_url: server_url + "/api/#region#/#table#/annees",
  api_mise_a_jour_perimetre_origine: server_url + "/api/#region#/date_perimetre",
  api_conversion_perimetre: server_url + "/api/#region#/conversion_perimetre",
  // supra goals
  api_objectifs: server_url + "/api/#region#/supra/goals/#supra_goal_id#/",
  api_integration_objectifs: server_url + "/api/#region#/supra/goals/",
  api_maj_objectif: server_url + "/api/#region#/supra/goals/#supra_goal_id#/",
  api_affectation_objectifs: server_url + "/api/#region#/supra/goals/#supra_goal_id#/link/territory",
  api_consultation_tableau_bord_url: server_url + "/api/#region#/consultation/tableau/bord/",
  api_consultation_autre_page_url: server_url + "/api/#region#/consultation/autre/page/",
  // POI
  api_poi_layers_url: server_url + "/api/#region#/poi/layers/",
  api_poi_layer_url: server_url + "/api/#region#/poi/layer/#layer#",
  api_poi_add_layer_url: server_url + "/api/#region#/poi/layer/add",
  api_poi_consult_layer_url: server_url + "/api/#region#/poi/layer/consult/#layer#",
  api_poi_layer_rights_url: server_url + "/api/#region#/poi/layer/#layer#/rights/",
  api_poi_export_layer_url: server_url + "/api/#region#/poi/layer/export/#layer#",
  api_poi_layer_import: server_url + "/api/#region#/import/common/poi/layer/#layerId#",
  api_poi_object_url: server_url + "/api/#region#/poi/object/#layer#/",
  api_poi_contributions_admin_url: server_url + "/api/#region#/admin/poi/contributions/",
  api_poi_contribution_manage_url: server_url + "/api/#region#/admin/poi/contribution/#edit_id#/#action#/",
  api_poi_history_url: server_url + "/api/#region#/poi/layers/history/",
  api_poi_order_layers_url: server_url + "/api/#region#/poi/layers/order/",
  api_poi_themes_url: server_url + "/api/#region#/poi/themes/",
  api_poi_theme_rename_url: server_url + "/api/#region#/poi/theme/rename",
  // Actions
  api_plan_actions_running_plan: server_url + "/api/#region#/actions",
  api_plan_actions_actions_old_url: server_url + "/api/#region#/actions/old",
  api_plan_actions_actions_url: server_url + "/api/#region#/actions",
  api_plan_actions_coeffs_url: server_url + "/api/#region#/actions/subactions",
  api_plan_actions_advanced_params_url: server_url + "/api/#region#/actions/params/advanced",
  // deprecated
  api_plan_actions_coeffs_reference_url: server_url + "/api/#region#/actions/params/reference",
  api_exporter_params_strategie: server_url + "/api/#region#/actions/export_params/",
  // unused
  api_charger_params_strategie: server_url + "/api/#region#/strategie/import/",
  // unused
  api_export_strategy_ademe: server_url + "/api/#region#/actions/export",
  api_export_strategy_results: server_url + "/api/#region#/strategie/results/export/",
  api_export_strategy_results_update_template: server_url + "/api/#region#/update/strategie/template/",
  api_export_strategy_results_get_template: server_url + "/api/#region#/get/strategie/template/",
  api_strategy_requisites: server_url + "/api/#region#/actions/requisites/",
  api_passage_table: server_url + "/api/#region#/passage/table/",
  api_strategy_errors: server_url + "/api/#region#/strategy/actions/errors/",
  api_notes_territorialsynthesis_url: server_url + "/api/#region#/territorialsynthesis/notes/",
  api_analysis_page_parameters: server_url + "/api/#region#/analysis/page/parameters/#page#/",
  api_suivi_trajectoire_energetique_url: server_url + "/api/#region#/analysis/suivi_trajectoire",
  api_meta_donnees_sankey: server_url + "/api/#region#/sankey/meta_donnees",
  api_list_sankeys: server_url + "/api/#region#/sankey/list",
  api_sankey_layout: server_url + "/api/#region#/sankey/layout/#table_name#",
  api_sankey_data: server_url + "/api/#region#/sankey/data/#table_name#",
  api_update_sankey_metadata: server_url + "/api/#region#/sankey/update/#table_name#",
  api_update_sankey_delete: server_url + "/api/#region#/sankey/delete/#table_name#",
  api_update_sankey_new: server_url + "/api/#region#/sankey/new/",
  api_contact_url: server_url + "/api/#region#/contact?",
  api_base_url: server_url + "/api",
  api_pop_up_accueil: server_url + "/api/#region#/mise_a_jour_pop_up",
  auth_url: server_url + "/api/#region#/auth",
  national_auth_url: server_url + "/api/user/#region#/get/user/regions",
  auth_refresh_url: server_url + "/api/#region#/auth/refresh",
  auth_logout_url: server_url + "/api/#region#/auth/logout",
  changelog_url: server_url + "/api/changelog",
  users_url: server_url + "/api/user/#region#/list",
  create_user_url: server_url + "/api/user/#region#/create",
  create_user_admin_url: server_url + "/api/user/#region#/admin/create",
  user_activate_url: server_url + "/api/user/#region#/activate",
  user_deactivate_url: server_url + "/api/user/#region#/deactivate",
  user_update_url: server_url + "/api/user/#region#/update",
  user_delete_url: server_url + "/api/user/#region#/delete/",
  user_request_new_password: server_url + "/api/user/#region#/password",
  user_set_new_password: server_url + "/api/user/#region#/setpassword",
  user_scenario_url: server_url + "/api/user/#region#/scenario",
  user_scenario_list_url: server_url + "/api/user/#region#/scenario/list",
  user_scenario_partage_url: server_url + "/api/user/#region#/scenario/#id#/partage",
  user_scenario_partage_desactive_url: server_url + "/api/user/#region#/scenario/#id#/desactive",
  user_scenario_partage_publier_url: server_url + "/api/user/#region#/scenario/#id#/publier",
  region_scenarios_url: server_url + "/api/#region#/scenarios",
  newsletter_url: server_url + "/api/#region#/newsletter",
  subscribe_other_territory_url: server_url + "/api/national/subscribe",
  tableau_bord_url: server_url + "/api/#region#/tableau",
  tableau_bord_dupliquer_url: server_url + "/api/#region#/tableau/dupliquer",
  tableau_bord_utilisateur_url: server_url + "/api/#region#/my/dashboards",
  tableau_bord_public_url: server_url + "/api/#region#/public/dashboards",
  tableau_bord_liste_affectations_url: server_url + "/api/#region#/tableau/#dashboard_id#/liste/affectations",
  tableau_bord_affectation_url: server_url + "/api/#region#/tableau/#dashboard_id#/affectation",
  tableau_bord_depublication_url: server_url + "/api/#region#/tableau/#dashboard_id#/depublication",
  tableau_bord_share_url: server_url + "/api/#region#/tableau/#dashboard_id#/share",
  mise_a_jour_des_pcaet_ademe: server_url + "/api/#region#/maj_pcaet_ademe",
  api_pcaet_trajectories_list: server_url + "/api/#region#/pcaet/trajectories/list",
  api_pcaet_trajectory_details: server_url + "/api/#region#/pcaet/trajectory/details/#trajectory_id#/",
  api_all_pcaet: server_url + "/api/#region#/pcaet/all",
  api_siren_assignment: server_url + "/api/#region#/siren/assignment",
  images_carousel: server_url + "/api/#region#/images_carousel",
  api_static_files: server_url + "/api/#region#/static/files/",
  api_static_file_update: server_url + "/api/#region#/static/file/#file_id#/",
  api_stats_types: server_url + "/api/#region#/stats/types/",
  api_stats_query: server_url + "/api/#region#/stats/#stat_type#/#from_date#/",
  api_logo_sources_list: server_url + "/api/#region#/logo_sources/list",
  api_logo_sources: server_url + "/api/#region#/#id#/logo_sources",
  api_delete_logo_sources: server_url + "/api/#region#/#id#/logo_sources",
  api_integration_logo_sources: server_url + "/api/#region#/logo_sources/add",
  api_update_logo_sources: server_url + "/api/#region#/#id#/logo_sources/update",
  api_integration_didactic_file: server_url + "/api/#region#/didactic_file/add",
  api_didactic_file: server_url + "/api/#region#/#id#/didactic_file",
  api_didactic_file_list: server_url + "/api/#region#/didactic_file/list",
  api_didactic_files_types_list: server_url + "/api/#region#/didactic_file/types/list",
  api_delete_didactic_file: server_url + "/api/#region#/#id#/didactic_file",
  api_update_didactic_file: server_url + "/api/#region#/#id#/didactic_file/update",
  api_simulator: server_url + "/api/#region#/#type#/simulator",
  api_national_simulator: server_url + "/api/#region#/#territory#/#type#/simulator",
  api_actions_list: server_url + "/api/#region#/actions/list",
  api_test_action: server_url + "/api/#region#/test/actions/#action#/",
  api_call_external_api: server_url + "/api/#region#/call/external/api/#api_key#/",
  api_get_external_api_list: server_url + "/api/#region#/get/external/api/list/",
  api_update_external_api_metadata: server_url + "/api/#region#/external/api/update/#slug#",
  api_update_external_api_delete: server_url + "/api/#region#/external/api/delete/#slug#",
  api_update_external_api_new: server_url + "/api/#region#/external/api/new/",
  methodo_url: "/pdf/#region#/",
  faq_url: "/pdf/#region#/",
  a_propos_url: "/pdf/#region#/",
  territorialsynthesis_methodo: "methodologie_CESBA.pdf",
  classification_methodo: "methodo_classification.pdf",
  // Override any config value with the local (or dev/test/prod) configuration
  ...localConfig
};
export default config;