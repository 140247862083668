/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import { get_input_param, get_inter_param, get_complex_action_param } from "./utils";

/**
 * This file gathers the intermediate calculations of air polllutants useful for the simulator
 */

/**
 *
 * @param {*} specific_input_param : specific input parameters to terristory
 * @param {*} generic_input_param : generic input paramateres
 * @param {*} generic_inter_param : generic intermediate paramateres
 * @param {*} pollutant_before : generic intermediate paramateres before action
 * @param {*} user_params : user paramateres
 * @param {*} level : level of the simulator (0, 1 or 2)
 * @param {*} type_fe : variable name of emission factors in database
 */

export const get_pollutant = (specific_input_param, generic_input_param, generic_inter_param, pollutant_before, user_params, level, type_fe) => {
  // get pollutant after action
  let pollutant_after = inter_calcul_after(specific_input_param, generic_input_param, generic_inter_param, pollutant_before, user_params, level, type_fe);
  let res = pollutant_before["before"]["total_emission_transport"] - pollutant_after["after"]["total_emission_transport"];
  return res;
};
const inter_calcul_after = (specific_input_param, generic_input_param, generic_inter_param, pollutant_before, user_params, level, type_fe) => {
  let result = {};

  // Move less (suffix _ML)

  /** C_ML : Distance parcourue après action - indice 100 domicile-travail Transport de personnes (100%) */
  const C_ML = user_params["1a"] * user_params["1b"] / (specific_input_param["Nombre d'actifs domicile-travail"] * specific_input_param["Distance moyenne domicile-travail"]);
  /** D_ML : Distance parcourue après action - indice 100 hors domicile-travail Transport de personnes (100%) */
  const D_ML = user_params["2a"] / 100;
  /** E_ML : Distance parcourue après action - indice 100 hors domicile-travail Transport de marchandise (100%) */
  const E_ML = user_params["3a"] / 100;

  // Move efficiently (suffix _ME)
  // substitution levers : vp - passanger transport
  const fe_vp_diesel_essence = get_inter_param(generic_inter_param, type_fe, "véhicule particulier", "diesel_essence");
  const fe_vp_elec = get_inter_param(generic_inter_param, type_fe, "véhicule particulier", "électrique");
  const fe_vp_gnv = get_inter_param(generic_inter_param, type_fe, "véhicule particulier", "GNV");

  // user params
  let diesel_essence_7a = get_complex_action_param(user_params, "7a", "diesel_essence");
  let elec_7a = get_complex_action_param(user_params, "7a", "elec");
  let gnv_7a = get_complex_action_param(user_params, "7a", "gnv");

  /** C_ME : Emissions moyenne par km avant action (g/veh.km) Transport de personnes Voiture */
  const C_ME = diesel_essence_7a * fe_vp_diesel_essence + elec_7a * fe_vp_elec + gnv_7a * fe_vp_gnv;

  // substitution levers : public transport
  const fe_pt_diesel_essence = get_inter_param(generic_inter_param, type_fe, "bus et cars", "diesel_essence");
  const fe_pt_elec = get_inter_param(generic_inter_param, type_fe, "bus et cars", "électrique");
  const fe_pt_gnv = get_inter_param(generic_inter_param, type_fe, "bus et cars", "GNV");
  const fe_pt_hydrogen = get_inter_param(generic_inter_param, type_fe, "bus et cars", "hydrogène");

  // user params
  let diesel_essence_8a = get_complex_action_param(user_params, "8a", "diesel_essence");
  let elec_8a = get_complex_action_param(user_params, "8a", "elec");
  let gnv_8a = get_complex_action_param(user_params, "8a", "gnv");
  let hydro_8a = get_complex_action_param(user_params, "8a", "hydro");

  /** D_ME = Emissions moyenne par km avant action (g/veh.km) Transport de personnes Bus et cars */
  const D_ME = diesel_essence_8a * fe_pt_diesel_essence + elec_8a * fe_pt_elec + gnv_8a * fe_pt_gnv + hydro_8a * fe_pt_hydrogen;

  // substitution levers : heavyweight transport
  const fe_hw_diesel_essence = get_inter_param(generic_inter_param, type_fe, "poids lourd", "diesel");
  const fe_hw_gnv = get_inter_param(generic_inter_param, type_fe, "poids lourd", "GNV");
  const fe_hw_hydrogen = get_inter_param(generic_inter_param, type_fe, "poids lourd", "hydrogène");

  // user params
  let diesel_essence_9a = get_complex_action_param(user_params, "9a", "diesel_essence");
  let gnv_9a = get_complex_action_param(user_params, "9a", "gnv");
  let hydro_9a = get_complex_action_param(user_params, "9a", "hydro");

  /** E_ME : Emissions moyenne par km avant action (g/veh.km) Transport de marchandise Poids lourds */
  const E_ME = diesel_essence_9a * fe_hw_diesel_essence + gnv_9a * fe_hw_gnv + hydro_9a * fe_hw_hydrogen;

  // walk and bike
  /** F_ME : Emissions moyenne par km avant action (g/veh.km) Transport de personnes Vélo / marche à pied */
  // const F_ME = 0;

  // 2. Move differently (suffix _MD)

  const defaultAssetNbrPerCar = get_input_param(generic_input_param, "domicile-travail", "Nombre d'actifs par voiture par défaut");
  const fillingRatePt = get_input_param(generic_input_param, "transport-personnes", "Taux de remplissage des bus/cars");
  const share_carpool_users_6a = get_complex_action_param(user_params, "6a", "share_carpool_users");
  const avg_carpoolers_6a = get_complex_action_param(user_params, "6a", "avg_carpoolers", false);

  /** C_MD : Emissions moyenne par km après action (g/veh.km) domicile-travail Transport de personnes  distance < 2km */
  const C_MD = (specific_input_param["Part modale voiture + 2 roues pour trajet < 2 km"] / 100 - (user_params["4a"] / 100 - specific_input_param["Part modale marche à pied / vélo pour trajet < 2 km"] / 100)) * C_ME / (share_carpool_users_6a * avg_carpoolers_6a + defaultAssetNbrPerCar * (1 - share_carpool_users_6a)) + specific_input_param["Part modale transport en commun pour trajet < 2 km"] / 100 * D_ME / fillingRatePt;

  /** D_MD : Emissions moyenne par km après action (g/veh.km) domicile-travail Transport de personnes distance 2-10km */
  const D_MD = (specific_input_param["Part modale voiture + 2 roues pour trajet [2-10] km"] / 100 - (user_params["4b"] / 100 - specific_input_param["Part modale marche à pied / vélo pour trajet [2-10] km"] / 100)) * C_ME / (share_carpool_users_6a * avg_carpoolers_6a + defaultAssetNbrPerCar * (1 - share_carpool_users_6a)) + specific_input_param["Part modale transport en commun pour trajet [2-10] km"] / 100 * D_ME / fillingRatePt;

  /** E_MD : Emissions moyenne par km après action (g/veh.km) domicile-travail Transport de personnes distance 10-80km */
  const E_MD = (specific_input_param["Part modale voiture + 2 roues pour trajet [10-80] km"] / 100 - (user_params["5a"] / 100 - specific_input_param["Part modale transport en commun pour trajet [10-80] km"] / 100)) * C_ME / (share_carpool_users_6a * avg_carpoolers_6a + defaultAssetNbrPerCar * (1 - share_carpool_users_6a)) + user_params["5a"] / 100 * D_ME / fillingRatePt;

  /** F_MD : Emissions moyenne par km après action (g/veh.km) domicile-travail Transport de personnes toute distance */
  const F_MD = (C_MD * specific_input_param["Nombre d'actifs trajet < 2 km"] * specific_input_param["Distance moyenne trajets < 2 km"] + D_MD * specific_input_param["Nombre d'actifs [2-10] km"] * specific_input_param["Distance moyenne [2-10] km"] + E_MD * specific_input_param["Nombre d'actifs [10-80] km"] * specific_input_param["Distance moyenne [10-80] km"]) / (specific_input_param["Nombre d'actifs domicile-travail"] * specific_input_param["Distance moyenne domicile-travail"]);

  /** G_MD : Emissions moyenne par km après action (g/veh.km) hors domicile-travail Transport de personnes toute distance */
  const G_MD = C_MD * specific_input_param["Part km hors travail < 2 km"] + D_MD * specific_input_param["Part km hors travail [2-10] km"] + E_MD * specific_input_param["Part km hors travail [10-80] km"];

  /** H_MD : Emissions moyenne par km après action (g/veh.km) transport de marchandise hors domicile-travail toute distance */
  const H_MD = E_ME;

  // final result after action

  const workedDaysPerYear = get_input_param(generic_input_param, "domicile-travail", "Nombre de jours travaillés par an");
  const dailyTrips = get_input_param(generic_input_param, "domicile-travail", "Nombre de trajets par jour");

  /** W : Emissions après action Transport de personnes domicile-travail */
  const W = F_MD * C_ML * specific_input_param["Nombre d'actifs domicile-travail"] * specific_input_param["Distance moyenne domicile-travail"] * workedDaysPerYear * dailyTrips / 1000000;

  /** Z : Total Emissions transport routier après action */
  let Z = 0;
  if (level === 0) {
    Z = pollutant_before["before"]["total_emission_transport"] - pollutant_before["before"]["trasport_passenger_home_work"] + W;
    result["after"] = {
      trasport_passenger_home_work: W,
      total_emission_transport: Z
    };
  } else {
    /** X : Emissions après action Transport de personnes hors domicile-travail */
    const X = pollutant_before["before"]["transport_passenger_off_home_work"] * G_MD / pollutant_before["move_differently"]["avg_emission_total_out_home_work"] * D_ML;
    /** Y : Emissions après action Transport de marchandise hors domicile-travail */
    const Y = pollutant_before["before"]["transport_goods_off_domicile_travail"] * H_MD / pollutant_before["move_differently"]["avg_emission_goods_transport"] * E_ML;
    Z = W + X + Y;
    result["after"] = {
      trasport_passenger_home_work: W,
      transport_passenger_off_home_work: X,
      transport_goods_off_domicile_travail: Y,
      total_emission_transport: Z
    };
  }
  return result;
};