// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminStyles-module__fullViewportWidth--CIjUt {\n    width: 100vw;\n    left: 50%;\n    transform: translateX(-50%);\n    position: relative;\n    overflow-x: auto;\n}\n\n.AdminStyles-module__poiButtons--ekQhg button {\n    margin: 0.25em;\n}\n\n.AdminStyles-module__poiIconsBlocks--WLQZw {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n}\n\n.AdminStyles-module__poiIconBlock--m1A7a {\n    border: 1px solid #c0c0c0;\n    border-radius: 10px;\n    max-width: 250px;\n    padding: 20px;\n    margin: 10px;\n    text-align: left;\n}\n\n.AdminStyles-module__poiLegend--QArzl img,\n.AdminStyles-module__poiLegend--QArzl object {\n    width: 23px;\n    max-height: 33px;\n}\n\n.AdminStyles-module__poiIconBlock--m1A7a span.AdminStyles-module__poiLegend--QArzl {\n    display: block;\n    margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Admin/AdminStyles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB;;AAEA;;IAEI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".fullViewportWidth {\n    width: 100vw;\n    left: 50%;\n    transform: translateX(-50%);\n    position: relative;\n    overflow-x: auto;\n}\n\n.poiButtons button {\n    margin: 0.25em;\n}\n\n.poiIconsBlocks {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n}\n\n.poiIconBlock {\n    border: 1px solid #c0c0c0;\n    border-radius: 10px;\n    max-width: 250px;\n    padding: 20px;\n    margin: 10px;\n    text-align: left;\n}\n\n.poiLegend img,\n.poiLegend object {\n    width: 23px;\n    max-height: 33px;\n}\n\n.poiIconBlock span.poiLegend {\n    display: block;\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullViewportWidth": "AdminStyles-module__fullViewportWidth--CIjUt",
	"poiButtons": "AdminStyles-module__poiButtons--ekQhg",
	"poiIconsBlocks": "AdminStyles-module__poiIconsBlocks--WLQZw",
	"poiIconBlock": "AdminStyles-module__poiIconBlock--m1A7a",
	"poiLegend": "AdminStyles-module__poiLegend--QArzl"
};
export default ___CSS_LOADER_EXPORT___;
