// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContinuousColorScaleLegend-module__colorScaleContainer--OyOhS {\n    height: 180px;\n    width: 100%;\n    display: flex;\n}\n\n.ContinuousColorScaleLegend-module__ticks--nXPap {\n    height: 100%;\n    width: 100%;\n    position: relative;\n}\n\n.ContinuousColorScaleLegend-module__tick--bRufj {\n    position: absolute;\n    display: flex;\n    gap: 0.25em;\n    align-items: center;\n    color: black;\n    transform: translateY(-50%);\n}\n\n.ContinuousColorScaleLegend-module__tick--bRufj::before {\n    content: \"\";\n    display: block;\n    height: 1px;\n    width: 4px;\n    background-color: #666;\n    margin-right: 4px;\n}\n\n.ContinuousColorScaleLegend-module__gradient--eHH93 {\n    height: 100%;\n    width: 20px;\n}\n\n.ContinuousColorScaleLegend-module__gradientStep--dE33F {\n    display: block;\n    width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Map/ContinuousColorScaleLegend.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,cAAc;IACd,WAAW;IACX,UAAU;IACV,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,cAAc;IACd,WAAW;AACf","sourcesContent":[".colorScaleContainer {\n    height: 180px;\n    width: 100%;\n    display: flex;\n}\n\n.ticks {\n    height: 100%;\n    width: 100%;\n    position: relative;\n}\n\n.tick {\n    position: absolute;\n    display: flex;\n    gap: 0.25em;\n    align-items: center;\n    color: black;\n    transform: translateY(-50%);\n}\n\n.tick::before {\n    content: \"\";\n    display: block;\n    height: 1px;\n    width: 4px;\n    background-color: #666;\n    margin-right: 4px;\n}\n\n.gradient {\n    height: 100%;\n    width: 20px;\n}\n\n.gradientStep {\n    display: block;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorScaleContainer": "ContinuousColorScaleLegend-module__colorScaleContainer--OyOhS",
	"ticks": "ContinuousColorScaleLegend-module__ticks--nXPap",
	"tick": "ContinuousColorScaleLegend-module__tick--bRufj",
	"gradient": "ContinuousColorScaleLegend-module__gradient--eHH93",
	"gradientStep": "ContinuousColorScaleLegend-module__gradientStep--dE33F"
};
export default ___CSS_LOADER_EXPORT___;
