// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiSelectLegend-module__LegendOptionContainer--FgX9I {\n    display: flex;\n    align-items: center;\n    margin-bottom: 0.25em;\n}\n\n.MultiSelectLegend-module__LegendColorSquare--BmsSV {\n    height: 12px;\n    flex: 1 0 12px;\n    margin-right: 8px;\n}\n\n.MultiSelectLegend-module__LegendLabel--ICyCL {\n    display: flex;\n    align-items: center;\n    margin-left: 8px;\n    text-wrap: nowrap;\n}\n\n.MultiSelectLegend-module__SelectDeselectAll--sk8Pj {\n    padding: 0;\n    margin-top: 0.25em;\n    border: none;\n    background-color: inherit;\n    cursor: pointer;\n    display: inline-block;\n    color: #666;\n    text-decoration: underline;\n}\n\n.MultiSelectLegend-module__SelectDeselectContainer--qLZnR {\n    color: #666;\n    margin-top: 0.25em;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Graphs/MultiSelectLegend.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,qBAAqB;IACrB,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".LegendOptionContainer {\n    display: flex;\n    align-items: center;\n    margin-bottom: 0.25em;\n}\n\n.LegendColorSquare {\n    height: 12px;\n    flex: 1 0 12px;\n    margin-right: 8px;\n}\n\n.LegendLabel {\n    display: flex;\n    align-items: center;\n    margin-left: 8px;\n    text-wrap: nowrap;\n}\n\n.SelectDeselectAll {\n    padding: 0;\n    margin-top: 0.25em;\n    border: none;\n    background-color: inherit;\n    cursor: pointer;\n    display: inline-block;\n    color: #666;\n    text-decoration: underline;\n}\n\n.SelectDeselectContainer {\n    color: #666;\n    margin-top: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LegendOptionContainer": "MultiSelectLegend-module__LegendOptionContainer--FgX9I",
	"LegendColorSquare": "MultiSelectLegend-module__LegendColorSquare--BmsSV",
	"LegendLabel": "MultiSelectLegend-module__LegendLabel--ICyCL",
	"SelectDeselectAll": "MultiSelectLegend-module__SelectDeselectAll--sk8Pj",
	"SelectDeselectContainer": "MultiSelectLegend-module__SelectDeselectContainer--qLZnR"
};
export default ___CSS_LOADER_EXPORT___;
