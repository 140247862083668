/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import { get_input_param, get_inter_param, get_complex_action_param } from "./utils";

/**
 * This file gathers the intermediate calculations of ghg emissions
 */

/**
 *
 * @param {*} A : specific input parameters
 * @param {*} B : generic input paramateres
 * @param {*} C : generic intermediate paramateres
 * @param {*} D : generic intermediate paramateres before action
 * @param {*} E : user paramateres
 * @param {*} level : level of the simulator (0, 1 or 2)
 */

export const get_ghg = (A, B, C, D, E, level) => {
  // get consumption after action
  let ghg_after = inter_calcul_after(A, B, C, D, E, level);
  let ghg = D["before"]["total_ghg_transport"] - ghg_after["after"]["total_ghg_transport"];
  return ghg;
};
const inter_calcul_after = (A, B, C, D, E, level) => {
  let ghg = {};

  // Move less (suffix _ML)
  /**
   * C_ML = Distance parcourue après action - indice 100 domicile-travail Transport de personnes (100%)
   * D_ML = Distance parcourue après action - indice 100 hors domicile-travail Transport de personnes (100%)
   * E_ML = Distance parcourue après action - indice 100 hors domicile-travail Transport de marchandise (100%)
   */

  const C_ML = E["1a"] * E["1b"] / (A["Nombre d'actifs domicile-travail"] * A["Distance moyenne domicile-travail"]);
  const D_ML = E["2a"] / 100;
  const E_ML = E["3a"] / 100;

  // Move efficiently (suffix _ME)
  /**
   * C_ME = Emissions moyenne par km après action (kWh/pers.km) Transport de personnes Voiture
   * D_ME = Emissions moyenne par km après action (kWh/pers.km) Transport de personnes Bus et cars
   * E_ME = Emissions moyenne par km après action (kWh/pers.km) Transport de marchandise Poids lourds
   * F_ME = Emissions moyenne par km après action (kWh/pers.km) Transport de personnes Vélo / marche à pied
   */

  // substitution levers : vp - passanger transport

  let emission_vp_diesel_essence = get_inter_param(C, "fe_ges", "véhicule particulier", "diesel_essence");
  let emission_vp_elec = get_inter_param(C, "fe_ges", "véhicule particulier", "électrique");
  let emission_vp_gnv = get_inter_param(C, "fe_ges", "véhicule particulier", "GNV");
  let emission_vp_hydro = get_inter_param(C, "fe_ges", "véhicule particulier", "hydrogène");
  let emission_vp_biognv = get_inter_param(C, "fe_ges", "véhicule particulier", "bioGNV");
  let emission_vp_biofuel = get_inter_param(C, "fe_ges", "véhicule particulier", "biocarburant");
  let emission_vp_greenHydro = get_inter_param(C, "fe_ges", "véhicule particulier", "hydrogènevert");

  // user params
  let diesel_essence_7a = get_complex_action_param(E, "7a", "diesel_essence");
  let elec_7a = get_complex_action_param(E, "7a", "elec");
  let gnv_7a = get_complex_action_param(E, "7a", "gnv");
  let hydro_7a = get_complex_action_param(E, "7a", "hydro");
  let biognv_7a = get_complex_action_param(E, "7a", "biognv_share");
  let biofuel_7a = get_complex_action_param(E, "7a", "biofuel_share");
  let greenhydro_7a = get_complex_action_param(E, "7a", "greenhydro_share");
  const C_ME = diesel_essence_7a * ((1 - biofuel_7a) * emission_vp_diesel_essence + biofuel_7a * emission_vp_biofuel) + elec_7a * emission_vp_elec + gnv_7a * ((1 - biognv_7a) * emission_vp_gnv + biognv_7a * emission_vp_biognv) + hydro_7a * ((1 - greenhydro_7a) * emission_vp_hydro + greenhydro_7a * emission_vp_greenHydro);

  // substitution levers : public transport
  let emission_pt_diesel_essence = get_inter_param(C, "fe_ges", "bus et cars", "diesel_essence");
  let emission_pt_elec = get_inter_param(C, "fe_ges", "bus et cars", "électrique");
  let emission_pt_gnv = get_inter_param(C, "fe_ges", "bus et cars", "GNV");
  let emission_pt_hydro = get_inter_param(C, "fe_ges", "bus et cars", "hydrogène");
  let emission_pt_biognv = get_inter_param(C, "fe_ges", "bus et cars", "bioGNV");
  let emission_pt_biofuel = get_inter_param(C, "fe_ges", "bus et cars", "biocarburant");
  let emission_pt_greenHydro = get_inter_param(C, "fe_ges", "bus et cars", "hydrogènevert");

  // user params
  let diesel_essence_8a = get_complex_action_param(E, "8a", "diesel_essence");
  let elec_8a = get_complex_action_param(E, "8a", "elec");
  let gnv_8a = get_complex_action_param(E, "8a", "gnv");
  let hydro_8a = get_complex_action_param(E, "8a", "hydro");
  let biognv_8a = get_complex_action_param(E, "8a", "biognv_share");
  let biofuel_8a = get_complex_action_param(E, "8a", "biofuel_share");
  let greenhydro_8a = get_complex_action_param(E, "8a", "greenhydro_share");
  const D_ME = diesel_essence_8a * ((1 - biofuel_8a) * emission_pt_diesel_essence + biofuel_8a * emission_pt_biofuel) + elec_8a * emission_pt_elec + gnv_8a * ((1 - biognv_8a) * emission_pt_gnv + biognv_8a * emission_pt_biognv) + hydro_8a * ((1 - greenhydro_8a) * emission_pt_hydro + greenhydro_8a * emission_pt_greenHydro);

  // substitution levers : heavyweight transport
  let emission_hw_diesel_essence = get_inter_param(C, "fe_ges", "poids lourd", "diesel");
  let emission_hw_gnv = get_inter_param(C, "fe_ges", "poids lourd", "GNV");
  let emission_hw_hydro = get_inter_param(C, "fe_ges", "poids lourd", "hydrogène");
  let emission_hw_biognv = get_inter_param(C, "fe_ges", "poids lourd", "bioGNV");
  let emission_hw_biofuel = get_inter_param(C, "fe_ges", "poids lourd", "biocarburant");
  let emission_hw_greenHydro = get_inter_param(C, "fe_ges", "poids lourd", "hydrogènevert");

  // user params
  let diesel_essence_9a = get_complex_action_param(E, "9a", "diesel_essence");
  let gnv_9a = get_complex_action_param(E, "9a", "gnv");
  let hydro_9a = get_complex_action_param(E, "9a", "hydro");
  let biognv_9a = get_complex_action_param(E, "9a", "biognv_share");
  let biofuel_9a = get_complex_action_param(E, "9a", "biofuel_share");
  let greenhydro_9a = get_complex_action_param(E, "9a", "greenhydro_share");
  const E_ME = diesel_essence_9a * ((1 - biofuel_9a) * emission_hw_diesel_essence + biofuel_9a * emission_hw_biofuel) + gnv_9a * ((1 - biognv_9a) * emission_hw_gnv + biognv_9a * emission_hw_biognv) + hydro_9a * ((1 - greenhydro_9a) * emission_hw_hydro + greenhydro_9a * emission_hw_greenHydro);

  // bike and walk
  // const F_ME = 0;

  // 2. Move differently (suffix _MD)
  /**
   * C_MD = Emissions moyenne par km après action (kWh/pers.km) domicile-travail Transport de personnes  distance < 2km
   * D_MD = Emissions moyenne par km après action (kWh/pers.km) domicile-travail Transport de personnes distance 2-10km
   * E_MD = Emissions moyenne par km après action (kWh/pers.km) domicile-travail Transport de personnes distance 10-80km
   * F_MD = Emissions moyenne par km après action (kWh/pers.km) domicile-travail Transport de personnes toute distance
   * G_MD = Emissions moyenne par km après action (kWh/pers.km) hors domicile-travail Transport de personnes toute distance
   * H_MD = Emissions moyenne par km après action (kWh/pers.km) transport de marchandise hors domicile-travail toute distance
   */

  let defaultAssetNbrPerCar = get_input_param(B, "domicile-travail", "Nombre d'actifs par voiture par défaut");
  let fillingRatePt = get_input_param(B, "transport-personnes", "Taux de remplissage des bus/cars");

  // user params
  const share_carpool_users_6a = get_complex_action_param(E, "6a", "share_carpool_users");
  const avg_carpoolers_6a = get_complex_action_param(E, "6a", "avg_carpoolers", false);

  // < 2 km
  const C_MD = (A["Part modale voiture + 2 roues pour trajet < 2 km"] / 100 - (E["4a"] / 100 - A["Part modale marche à pied / vélo pour trajet < 2 km"] / 100)) * C_ME / (share_carpool_users_6a * avg_carpoolers_6a + defaultAssetNbrPerCar * (1 - share_carpool_users_6a)) + A["Part modale transport en commun pour trajet < 2 km"] / 100 * D_ME / fillingRatePt;

  // [2-10] km
  const D_MD = (A["Part modale voiture + 2 roues pour trajet [2-10] km"] / 100 - (E["4b"] / 100 - A["Part modale marche à pied / vélo pour trajet [2-10] km"] / 100)) * C_ME / (share_carpool_users_6a * avg_carpoolers_6a + defaultAssetNbrPerCar * (1 - share_carpool_users_6a)) + A["Part modale transport en commun pour trajet [2-10] km"] / 100 * D_ME / fillingRatePt;

  // [10-80] km
  const E_MD = (A["Part modale voiture + 2 roues pour trajet [10-80] km"] / 100 - (E["5a"] / 100 - A["Part modale transport en commun pour trajet [10-80] km"] / 100)) * C_ME / (share_carpool_users_6a * avg_carpoolers_6a + defaultAssetNbrPerCar * (1 - share_carpool_users_6a)) + E["5a"] / 100 * D_ME / fillingRatePt;

  // All distances (<80km)

  const F_MD = (C_MD * A["Nombre d'actifs trajet < 2 km"] * A["Distance moyenne trajets < 2 km"] + D_MD * A["Nombre d'actifs [2-10] km"] * A["Distance moyenne [2-10] km"] + E_MD * A["Nombre d'actifs [10-80] km"] * A["Distance moyenne [10-80] km"]) / (A["Nombre d'actifs domicile-travail"] * A["Distance moyenne domicile-travail"]);

  // All distance out home-work

  const G_MD = C_MD * A["Part km hors travail < 2 km"] + D_MD * A["Part km hors travail [2-10] km"] + E_MD * A["Part km hors travail [10-80] km"];

  // goods transport - All distance (out home-work)

  const H_MD = E_ME;

  // final result after action
  /**
   * W : Emissions après action Transport de personnes domicile-travail
   * X : Emissions après action Transport de personnes hors domicile-travail
   * Y : Emissions après action Transport de marchandise hors domicile-travail
   * Z : Total Emissions transport routier après action
   */

  let workedDaysPerYear = get_input_param(B, "domicile-travail", "Nombre de jours travaillés par an");
  let dailyTrips = get_input_param(B, "domicile-travail", "Nombre de trajets par jour");
  const W = F_MD * C_ML * A["Nombre d'actifs domicile-travail"] * A["Distance moyenne domicile-travail"] * workedDaysPerYear * dailyTrips / 1000000000;
  if (level === 0) {
    const Z = D["before"]["total_ghg_transport"] - D["before"]["trasport_passenger_home_work"] + W;
    ghg["after"] = {
      trasport_passenger_home_work: W,
      total_ghg_transport: Z
    };
  } else {
    const X = D["before"]["transport_passenger_off_home_work"] * G_MD / D["move_differently"]["ghg_total_out_home_work"] * D_ML;
    const Y = D["before"]["transport_goods_off_domicile_travail"] * H_MD / D["move_differently"]["ghg_goods_transport"] * E_ML;
    const Z = W + X + Y;
    ghg["after"] = {
      trasport_passenger_home_work: W,
      transport_passenger_off_home_work: X,
      transport_goods_off_domicile_travail: Y,
      total_ghg_transport: Z
    };
  }
  return ghg;
};